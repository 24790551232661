//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

import OpenData from '@/views/fileStream/documentManage/weixin/openData'

import { STable, XCard } from '@/components'
import { Empty } from 'ant-design-vue'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { wx_user } from '@/api/modular/fileStream/user'
export default {
  components: {
    STable,
    XCard,
    OpenData,
  },
  data() {
    return {
      visible: false,
      sp_index: null,
      value: null,
      orgTree: [],
      selectedRows: [],
      selectedRows_new: [],
      sexDictTypeDropDown: [],
      statusDictTypeDropDown: [],
      treeLoading: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      replaceFields: {
        children: 'mychildren',
      },
      ck_index: null,
      user_list_name: null,
      userId_arr: [],
    }
  },
  created() {
    this.getTree()
    this.sysDictTypeDropDown()
  },
  methods: {
    getTree() {
      /**
       * 獲取到機構樹，展開頂級下樹節點，考慮到後期數據量變大，不建議全部展開
       */
      wx_user()
        .then((res) => {
          this.treeLoading = false
          if (!res.success) {
            return
          }
          //
          const mydata = Object.assign([], res.data)
          async function make_mychildren(data) {
            data.forEach((element) => {
              //
              element.mychildren = element.children.concat(element.wxCorpUserList)
              //
              if (element.children && element.children.length > 0) {
                make_mychildren(element.children)
              } else {
                return
              }
            })
            return data
          }
          async function make_slot(data) {
            data.forEach((element) => {
              element.scopedSlots = { title: 'custom' }
              if (element.mychildren && element.mychildren.length > 0) {
                make_slot(element.mychildren)
              } else {
                return
              }
            })
            return data
          }
          //
          make_mychildren(mydata)
            .then((x) => {
              return make_slot(x)
            })
            .then((x) => {
              console.log(x)
              this.orgTree = x
            })
        })
        .then((x) => {
          init_wx()
        })
    },
    add(list, i) {
      console.log(list, i)
      this.ck_index = null
      this.sp_index = i
      this.selectedRows_new = []
      this.userId_arr = []
      //
      if (i === undefined) {
        this.user_list_name = list
        this.selectedRows = this.$parent[list]
      } else if (typeof i === 'number') {
        this.user_list_name = list
        this.selectedRows = this.$parent[list][i].val
      }
      //
      this.selectedRows.map((x) => {
        this.userId_arr.push(x.userId)
      })
      console.log(this.selectedRows, this.userId_arr)
      //
      this.visible = true
    },
    sexFilter(sex) {
      const values = this.sexDictTypeDropDown.filter((item) => item.code == sex)
      if (values.length > 0) {
        return values[0].value
      }
    },
    statusFilter(status) {
      const values = this.statusDictTypeDropDown.filter((item) => item.code == status)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown(text) {
      sysDictTypeDropDown({ code: 'sex' }).then((res) => {
        this.sexDictTypeDropDown = res.data
      })
      sysDictTypeDropDown({ code: 'common_status' }).then((res) => {
        this.statusDictTypeDropDown = res.data
      })
    },

    handleSubmit() {
      const { sp_index, selectedRows, selectedRows_new, user_list_name } = this
      const length = selectedRows.length + selectedRows_new.length
      if (length < 1) {
        this.$message.error('請選擇！')
        return
      } else {
        const rows = [].concat(selectedRows).concat(selectedRows_new)
        if (typeof sp_index === 'number') {
          this.$parent[user_list_name][sp_index].val = rows
        } else {
          this.$parent[user_list_name] = rows
        }
        console.log(rows)
        this.visible = false
      }
    },
    handleCancel() {
      this.visible = false
    },
    select_user(item) {
      if (this.userId_arr.indexOf(item.userId) !== -1) {
        return
      }
      this.ck_index = item.userId
      this.selectedRows_new = [item]
    },
    del_user() {
      this.selectedRows_new = []
      this.ck_index = null
    },
    callback(key) {
      console.log(key)
    },
  },
}
